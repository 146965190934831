import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

import List from "../layouts/list"
import Main from "../components/main"
import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import Referencelist from "../components/reference-list"
import Container from "../layouts/container"
import Breadcrumbs from "../components/chrome/breadcrumbs"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const PageReferences = () => {
    const query = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark(
                    filter: {fileAbsolutePath: {regex: "/(content/references/)/"  }},
                    sort: { fields: [frontmatter___date], order: DESC }
                ) {
                totalCount
                edges {
                    node {
                    id
                    frontmatter {
                        title
                        subtitle
                        date
                        dateModified
                        datePublished
                        tags
                    }
                    fields {
                        slug
                    }
                    excerpt
                    }
                }
                }
            }
        `
    )

    const clientList = [
        {
            url: "https://hdi.de",
            title: 'HDI'
        },
        {
            url: "https://wwf.de",
            title: 'WWF'
        },
        {
            url: "https://unilever.de",
            title: 'Unilever'
        },
        {
            url: "https://swatch.com",
            title: 'Swatch'
        },
        {
            url: "https://o2.de",
            title: 'Alice (Hansenet)'
        },
        {
            url: "https://messe.de",
            title: 'Deutsche Messe'
        },
        {
            url: "https://tchibo.de",
            title: 'Tchibo'
        },
        {
            url: "https://rosier.de",
            title: 'Rosier'
        },
        {
            url: "https://knierim-yachtbau.de",
            title: 'Knierim Yachtbau'
        },
        {
            url: "https://sabio.de",
            title: 'Sabio'
        },
        {
            url: "https://dpd.de",
            title: 'DPD'
        },
        {
            url: "https://www.deutschesee.de/",
            title: 'Deutsche See'
        },
    ]

    return (
        <List>
            <SEO
                title="My references"
                description="I've listed a couple of projects here that I've contributed to."
            />
            <PageHeader headerText="Work work work work work work (Rihanna)" />

            <Main>
                <Container>
                    <Breadcrumbs title="References" path="/references" />
                </Container>

                <div className="row">
                    <div className="col--16">
                        <Container>
                            <h1 className="h1">References</h1>
                            <p>I've worked for a number of clients in different jobs. Please have a look at some examples that I find especially noteworthy in the list below.</p>
                            <p>My main goals were and always will be delivering the best possible UX and usability for the end-users.</p>
                            <Referencelist data={query} />
                        </Container>
                        <Container>
                            <h1 className="h1">Client list (excerpt)</h1>
                            <ul className="inlineList--wrapped">
                                {clientList.map(client => {
                                    const { title, URL } = client;
                                    return (
                                        <li className="inlineList__item"><a className="chip" href={URL} target="_blank" rel="noopener noreferrer">{title}</a></li>
                                    )
                                })}
                            </ul>
                        </Container>
                    </div>
                </div>
            </Main>

            <PageFooter />
        </List>
    )
}

export default PageReferences